import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph } from "@siteimprove/fancylib";
import * as FancyVisuals from "@siteimprove/fancyvisuals";
import { DocPageMeta, InlineMessage } from "../../../../../src/docs";
import { ContentSection, HeaderSection } from "../../../../../src/docs/sections";
import { Illustration } from "./illustration";
import * as scss from "./illustrations-overview.scss";

export const Meta: DocPageMeta = {
	category: "Visuals",
	title: "Illustrations Overview",
	notepad: "https://hackmd.io/6SmucTEMQxyHFPOlhCyvAA",
};

const Illustrations = Object.entries(FancyVisuals).filter(([key]) =>
	key.startsWith("Illustration")
);

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Illustrations Overview"
			subTitle="An overview of all illustrations available for use."
		/>
		<ContentSection>
			<InlineMessage variant="info">
				Can't find the{" "}
				<GatsbyLink to="/lab/components/Visuals/Illustration">illustration</GatsbyLink> you're
				looking for? Consult the{" "}
				<GatsbyLink to="/Designing/illustrations-FAQ">Illustrations FAQ</GatsbyLink> page to find
				out what to do.
			</InlineMessage>
			<div className={scss.layout}>
				{Illustrations.map(([key, illustration]) => (
					<div key={key} className={scss.container}>
						<Illustration size="small" src={illustration} decorative />
						<Paragraph size="xSmall">{key}</Paragraph>
					</div>
				))}
			</div>
		</ContentSection>
	</>
);
